import styled from "styled-components";

import { AiOutlinePoweroff } from "react-icons/ai";

//header
export const Header = styled.header`
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    @media screen and (min-width: 1000px) {
        width: 90vw;
        margin: 5px 5vw;
    }
    `

export const Logo = styled.img`
    height: 40px;
    border-radius: 10px;
    margin-left: 5px;
    transition: all .2s ease;
    filter: drop-shadow(0 0 #0002);

    @media screen and (min-width: 1000px) {
        :hover {
            transform: translateY(-2.5px);
            filter: drop-shadow(0 2.5px #0002);
        }
    }
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`

export const LogoutIcon = styled(AiOutlinePoweroff)`
        order: 1;
        cursor: pointer;
        padding: 5px;
        box-sizing: unset;
        color: #aaa;
        transition: color .2s ease;

        :hover {
            color: var(--color-dark-blue);
        }
`

export const Logout = styled.span`
    order: 2;
    display: none;

    @media screen and (min-width: 1000px) {
        display: block;
        position: relative;
        cursor: pointer;
        bottom: 1.5px;
        box-sizing: unset;
        padding: 10px;
        
        :before{
            transition: width .2s ease;
            border-radius: 20px;
            content: '';
            position: absolute;
            width: 0%;
            height: 1px;
            background-color: var(--color-dark-blue);
            bottom: 0;
            left: 0;
        }
        :hover {
            ~svg {
                color: var(--color-dark-blue);
            }

            ::before {
                width: 100%;
            }
        }
    }
`