import styled from "styled-components";

import { BiImageAdd } from "react-icons/bi";

export const AddImageBox = styled.label`
  width: 90%;
  height: 225px;
  margin: 0 5%;
  background-color: var(--color-light-grey);
  background-image: ${(props) =>
    props.imageUrl && !props.file && `url(https://admin.battelinifrenos.com.ar/public/${props.imageUrl})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 21px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: ${(props) => props.imageUrl || `2px dashed var(--color-dark-blue)`};
  color: var(--color-dark-blue);
  font-weight: 700;

  @media screen and (min-width: 600px) {
    height: 350px;
    cursor: pointer;
  }
`;

export const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  backdrop-filter: ${(props) => props.imageUrl && "blur(5px)"};
  background-color: ${(props) => props.imageUrl && "#fff5"};
  box-shadow: ${(props) => props.imageUrl && "0 0 10px #0005"};

  svg,
  span {
    text-align: center;
  }
`;

export const File = styled.input`
  display: none;
`;

export const AddImageIcon = styled(BiImageAdd)`
  font-size: 50px;
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: ${(props) => props.imageState || "none"};
`;
