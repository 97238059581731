import { AsideBox, BoxButton, AsideImgBox, AsideImg } from "./StylesAside";
import { BoxTitle, BoxText } from "./StylesHome";

import { Link } from "react-router-dom";

import asideImg from "../../assets/venta-repuestos.png";
import { memo } from "react";

import { AiOutlineWhatsApp } from "react-icons/ai";

const Aside = memo(() => {
  const handleLink = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div
      style={{
        perspective: "2500px",
      }}
    >
      <AsideBox>
        <BoxTitle>VENTA DE REPUESTOS</BoxTitle>
        <BoxText width={"50"}>
          Nuestro local de venta de repuestos cuenta con personal calificado para asesorarte y el
          stock necesario dar soluciones eficientes a nuestros clientes
        </BoxText>
        <BoxButton>
          <Link onClick={handleLink} to={"/ventas"}>
            <AiOutlineWhatsApp />
            Visitá el catálogo de repuestos
          </Link>
        </BoxButton>
        <AsideImgBox>
          <AsideImg src={asideImg} alt={"Imagen de fondo"} />
        </AsideImgBox>
      </AsideBox>
    </div>
  );
});

export default Aside;
