import {
  ModalBg,
  ModalContainer,
  ModalTitle,
  InputsContainer,
  ProductLabel,
  ProductInput,
  ProductBox,
  ProductFragment,
  ProductBoxInput,
  ProductDescription,
  SaveButtons,
  CloseButton,
  SaveButton,
} from "./StylesModal";

import { createPortal } from "react-dom";
import { useState, useContext, useRef } from "react";

import { Context } from "../../Context/ContextProvider";

import ImageModal from "./ImageModal";

import { sendProduct } from "../../../utils/sendProduct";
import { SelectCategory, SelectDiv, SelectLabel } from "../StylesProducts";

export default function ModalAddProduct({ closeModal, categories }) {
  const { reloadProducts, token } = useContext(Context);

  const [inputValues, setInputValues] = useState({
    name: "",
    price: "",
    promotion: "0",
    description: "",
    category: "",
    tags: "",
  });

  const handleChange = (e) => {
    setInputValues((prevValues) => {
      return {
        ...prevValues,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fileRef = useRef(null);

  const saveProduct = async (priv) =>
    await sendProduct(
      priv,
      fileRef.current.files[0],
      token,
      inputValues,
      closeModal,
      reloadProducts
    );

  const handleSelectCategories = (e) => {
    const value = e.target.value;
    setInputValues({ ...inputValues, category: value === "false" ? false : value.toUpperCase() });
  };

  return createPortal(
    <ModalBg>
      <ModalTitle>Nuevo Producto</ModalTitle>

      <ModalContainer onSubmit={(e) => e.preventDefault()}>
        <ImageModal text={"Agregar"} ref={fileRef} />
        <InputsContainer>
          <div style={{ position: "relative" }}>
            <ProductLabel left={"37"}>Nombre</ProductLabel>
            <ProductInput
              placeholder={"Nombre"}
              value={inputValues.name}
              name={"name"}
              onChange={handleChange}
            />
          </div>
          <ProductBox>
            <ProductFragment width={50}>
              <ProductLabel left={"0"}>Precio</ProductLabel>
              <ProductBoxInput placeholder={"Precio"} name={"price"} onChange={handleChange} />
            </ProductFragment>
            <ProductFragment width={50}>
              <ProductLabel left={"0"}>Promocion</ProductLabel>
              <ProductBoxInput
                placeholder={"Promocion"}
                name={"promotion"}
                onChange={handleChange}
                value={inputValues.promotion}
              />
            </ProductFragment>
          </ProductBox>

          <SelectDiv>
            <SelectLabel>Categoria</SelectLabel>
            <SelectCategory onChange={handleSelectCategories}>
              {categories.length ? (
                <>
                  <option value={false}>Todas las categorias</option>
                  {categories.map((item) => (
                    <option key={item}>{item.slice(0, 1) + item.slice(1).toLowerCase()}</option>
                  ))}
                </>
              ) : (
                <option>Cargando categorias</option>
              )}
            </SelectCategory>
          </SelectDiv>

          <div style={{ position: "relative" }}>
            <ProductLabel left={"37"}>Descripcion</ProductLabel>
            <ProductDescription
              placeholder={"Descripcion"}
              value={inputValues.description}
              name={"description"}
              onChange={handleChange}
            />
          </div>
          <div style={{ position: "relative" }}>
            <ProductLabel left={"37"}>Tag</ProductLabel>
            <ProductInput
              placeholder={"Tag"}
              value={inputValues.tags}
              name={"tags"}
              onChange={handleChange}
            />
          </div>
        </InputsContainer>

        <SaveButtons>
          <SaveButton bg={"dark"} onClick={() => saveProduct(false)} order={3}>
            Publicar
          </SaveButton>
          <SaveButton color={"dark"} onClick={() => saveProduct(true)} order={2}>
            Guardar como borrador
          </SaveButton>
          <CloseButton onClick={closeModal}>Cancelar</CloseButton>
        </SaveButtons>
      </ModalContainer>
    </ModalBg>,

    document.body
  );
}
