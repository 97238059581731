import { AddImageBox, ImageBox, AddImageIcon, File, Image } from "./StylesImageModal";

import { forwardRef } from "react";

import { toastInfo, toastWarn } from "../../../utils/Toast";
import { useState } from "react";

const ImageModal = forwardRef((props, ref) => {
  const { text, imageUrl } = props;
  const [fileImage, setFileImage] = useState(imageUrl);
  const [imageState, setImageState] = useState(false);

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      toastInfo("Imagen seleccionada!");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileImage(reader.result);
        setImageState(true);
      };
    } else {
      toastWarn("Imagen NO seleccionada");
      setFileImage(imageUrl);
      setImageState(false);
    }
  };

  return (
    <div>
      <AddImageBox htmlFor={"image"} imageUrl={imageUrl} file={ref.current?.files[0]}>
        <Image src={fileImage} imageState={imageState} />
        <ImageBox imageUrl={fileImage}>
          <AddImageIcon />
          <span>{text} imagen</span>
        </ImageBox>
      </AddImageBox>
      <File ref={ref} type="file" id="image" onChange={handleImage} />
    </div>
  );
});

export default ImageModal;
