import {
  ProductsControllers,
  DeleteBox,
  DeleteIcon,
  ImportIcon,
  ImportExportContainer,
  ImportExcelBtn,
  ImportButton,
  ExportButton,
  SearchFilterContainer,
  SearchBox,
  SearchInput,
  SearchIcon,
  FilterBox,
  FilterSelect,
  ToggleButtonBox,
  BackButton,
} from "./StylesControllers";

import ModalAddProduct from "./Modals/ModalAddProduct";

import { useContext, useState, useRef, useCallback } from "react";
import { Context } from "../Context/ContextProvider";

import ToggleButton from "./ToggleButton/ToggleButton";

import { orderData } from "../../utils/orderData";
import { exportToExcel } from "../../utils/exportToExcel.js";

import { toastSuccess, toastError, toastWarn } from "../../utils/Toast.js";
import { swalDelete, swalQuestion, swalResponse } from "../../utils/Swal";

import { getExcelData } from "../../utils/getExcelData";

import { CiImport, CiExport } from "react-icons/ci";
import { Select } from "../Shop/StylesShop";
import { useEffect } from "react";

export default function AdminControllers(props) {
  const {
    setProducts,
    route,
    handleSelection,
    setMsgProducts,
    handleSelectCategories,
    categories,
    setCategories,
    state,
    dispatch,
    category,
    setSearch,
  } = props;

  const { deleteProducts, setDeleteProducts, reloadProducts, token, showPrice, setShowPrice } = useContext(Context);

  const [back, setBack] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal(!modal);

  const fetchPriceRef = useRef(null);
  const handlePrice = () => {
    if (fetchPriceRef.current) {
      clearTimeout(fetchPriceRef.current);
    }

    setShowPrice(!showPrice);
    fetchPriceRef.current = setTimeout(() => {
      fetch("https://admin.battelinifrenos.com.ar/showPrice", {
        method: "POST",
        body: JSON.stringify({
          showPrice: {
            show: !showPrice,
          },
        }),
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      });
    }, 500);
  };

  const handleDelete = () => {
    swalDelete({
      title: "Estas seguro que quieres eliminar los productos seleccionados?",
      text: "Una vez eliminados no se podran recuperar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch("https://admin.battelinifrenos.com.ar/api/products", {
          method: "DELETE",
          body: JSON.stringify({ productsId: deleteProducts }),
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        })
          .then((res) => res.json())
          .then((data) => (data.error ? toastError(data.error) : toastSuccess(data.msg)))
          .then(setDeleteProducts([]))
          .then(reloadProducts)
          .catch((error) => toastWarn("No se han podido eliminar los productos"));
      }
    });
  };

  const handleSearchChange = (e) => setSearchValue(e.target.value);

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (searchValue !== "") {
        const params = `?limit=${state.limit}&offset=${state.offset}&timestamp=${Date.now()}&${
          category && "category=" + category
        }`;
        fetch(`https://admin.battelinifrenos.com.ar/api/products${route}${params}`, {
          method: "GET",
          headers: {
            authorization: token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            data.length || setMsgProducts("No se encontraron coincidencias");
            const products = data.filter((item) => item.data.name.toLowerCase().includes(searchValue.toLowerCase()));
            setProducts(products);
            setBack(true);
            setSearch((prev) => !prev);
            const myData = products.length;
            if (myData > state.limit) dispatch({ type: "setMaxPage", payload: Math.ceil(myData / state.limit) });
            else dispatch({ type: "setMaxPage", payload: 1 });
          });
        dispatch({ type: "setAllCheck", payload: false });
      }
    },
    [searchValue, route, setMsgProducts, setProducts, token, state.offset, state.limit]
  );

  const handleBack = () => {
    setBack(false);
    setSearchValue("");
    reloadProducts();
  };

  const handleExport = async () => {
    const { headTable, bodyTable } = await orderData(token);
    exportToExcel(headTable, bodyTable);
  };

  async function handleInputChange(event) {
    const target = event.target;
    const excelData = await getExcelData(target);

    await swalQuestion({
      title: "Estas seguro que quieres importar el siguiente archivo?",
      text: `${target.files[0].name}`,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch("https://admin.battelinifrenos.com.ar/api/products/importExcel", {
          method: "POST",
          body: JSON.stringify({ file: excelData }),
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              swalResponse({ title: "ERROR", text: `${data.error}`, icon: "error" });
              target.value = "";
            } else {
              swalResponse({ title: "Operacion exitosa!", text: `${data.msg}`, icon: "success" });
              target.value = "";
              reloadProducts();
            }
          })
          .catch((error) => toastWarn("Ha ocurrido un error inesperado"));
      } else {
        target.value = "";
      }
    });
  }

  useEffect(() => {
    fetch("https://admin.battelinifrenos.com.ar/api/products/categoryNames")
      .then((res) => res.json())
      .then((data) => setCategories(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <ProductsControllers>
        <DeleteBox onClick={handleDelete} selectedProducts={deleteProducts.length}>
          <DeleteIcon />
        </DeleteBox>
        <ImportExportContainer>
          <ExportButton onClick={handleExport}>
            <CiExport />
          </ExportButton>
          <ImportExcelBtn htmlFor="file">
            <CiImport />
          </ImportExcelBtn>
          <input type={"file"} id={"file"} onChange={handleInputChange} style={{ display: "none" }} />
          <ImportButton onClick={modalToggle}>
            <ImportIcon />
            Agregar nuevo
          </ImportButton>
        </ImportExportContainer>

        <SearchFilterContainer selectedProducts={deleteProducts.length}>
          <SearchBox onSubmit={handleSearch}>
            <SearchInput
              required
              onChange={handleSearchChange}
              value={searchValue}
              type="text"
              placeholder={"Buscar"}
            />
            <SearchIcon onClick={handleSearch} />
            <input type="submit" hidden />
          </SearchBox>
          <FilterBox>
            <span>Estado:</span>
            <FilterSelect onChange={handleSelection}>
              <option defaultValue>Todos</option>
              <option>Publicados</option>
              <option>Borrador</option>
            </FilterSelect>
          </FilterBox>
        </SearchFilterContainer>
      </ProductsControllers>
      {back && <BackButton onClick={handleBack}>Volver</BackButton>}
      <ToggleButtonBox>
        <span>Mostrar precios</span>
        <ToggleButton showPrice={showPrice} handlePrice={handlePrice} />
      </ToggleButtonBox>
      <Select onChange={handleSelectCategories} marginLeft={true}>
        {categories.length ? (
          <>
            <option value={false}>Todas las categorias</option>
            {categories.map((item) => (
              <option key={item}>{item.slice(0, 1) + item.slice(1).toLowerCase()}</option>
            ))}
          </>
        ) : (
          <option>Cargando categorias</option>
        )}
      </Select>
      {modal && <ModalAddProduct closeModal={modalToggle} categories={categories} />}
    </>
  );
}
