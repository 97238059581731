import { verifyFields } from "./verifyFields";
import { getImageName } from "./getImageName";
import { toastSuccess, toastError, toastWarn } from "./Toast";
import { deleteImage } from "./deleteImage";

export const sendProduct = async (priv, file, token, inputValues, closeModal, reloadProducts) => {
  if (!file) {
    return toastError("Error: Imagen no seleccionada");
  }

  const resImage = await getImageName(file, token);
  const image = await resImage.json();

  if (image.error) {
    return toastError("Error: Imagen no seleccionada");
  }

  const areValid = verifyFields({ ...inputValues, image }, 1);

  if (!areValid) {
    deleteImage(image, token);
    return toastError("Error: todos los campos tienen que estar completos y con 1 caracter mínimo");
  }

  const res = await fetch(`https://admin.battelinifrenos.com.ar/api/products/existName`, {
    method: "POST",
    body: JSON.stringify({ name: inputValues.name }),
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  });
  const data = await res.json();

  if (data.error) {
    deleteImage(image, token);
    return toastError(data.error);
  }

  const boolean = priv ? "public" : "priv";
  fetch(`https://admin.battelinifrenos.com.ar/api/products?type=${boolean}`, {
    method: "POST",
    body: JSON.stringify({ ...inputValues, image }),
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  })
    .then(closeModal())
    .then((res) => res.json())
    .then((data) => (data.error ? toastError(data.error) : toastSuccess("Producto agregado exitosamente!")))
    .then(reloadProducts)
    .catch((error) => toastWarn("No se ha podido agregar el producto"));
};
