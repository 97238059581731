import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import { Main } from './StylesLayout'

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      <Main>
        {children}
      </Main>
      <Footer />
    </>
  );
}
