import styled from "styled-components";

export const Container = styled.div`
    background-color: var(--color-white);
    border-radius: 10px;
    margin: 10px 0;
    padding: 5px 0;

    @media screen and (min-width: 1000px){
        padding: 50px;
    }
`

export const Title = styled.h1`
    text-align: center;
    font-size: 60px;
    margin: 10vh 0;
    letter-spacing: 2.5px;
    font-weight: 550;

    @media screen and (min-width: 1000px){
        font-size: 150px;
        margin: 5vh 0;
    }
`

export const Form = styled.form`
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
    gap: 20px;

    @media screen and (min-width: 1000px){
        width: 530px;
    }
`

export const InputBox = styled.div`
   display: flex;
   flex-direction: column;
   width: 90%;
   gap: 2.5px;
`

export const Label = styled.label`
    font-weight: 800;
    width: max-content;

    ::after {
        content: '(Requerido)';
        margin-left: 5px;
        font-size: 12.5px;
        opacity: .5;
        font-weight: 500;
        cursor: default;
    }

    @media screen and (min-width: 1000px){
        cursor: pointer;
    }
`

const defaultInput = `
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    background-color: var(--color-grey);
`

export const Input = styled.input`
    ${defaultInput}
`

export const Textarea = styled.textarea`
    ${defaultInput}
    height: 150px;
    resize: none;
`

export const Send = styled.input`
    border-radius: 5px;
    width: 90%;
    border: none;
    background-color: var(--color-dark-blue);
    color: var(--color-white);
    padding: 15px 0;
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;

    @media screen and (min-width: 1000px){
        cursor: pointer;
    }
`