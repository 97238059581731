export const deleteImage = (image, token) => {
  fetch("https://admin.battelinifrenos.com.ar/api/products/image", {
    method: "DELETE",
    body: JSON.stringify({ image }),
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  });
};
