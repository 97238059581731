import Layout from "../Layout/Layout";

import { Helmet } from "react-helmet-async";

import { MainTitleContainer, MainTitle, MainSubtitle } from "../Workshop/StylesWorkshop";
import {
  SearchBox,
  Search,
  SearchIcon,
  ProductsContainer,
  ProductStyled,
  ProductMsg,
  ShowMoreBtn,
  Select,
} from "./StylesShop";

import { useEffect, useId, useContext, useReducer } from "react";
import { Context } from "../Context/ContextProvider";

import ProductLoader from "./Loader";

import Product from "./Product";

import ShopImg from "../../assets/Shop.png";

export default function Shop() {
  const { showPrice, refresh, reloadProducts } = useContext(Context);

  const initialState = {
    products: [],
    loading: true,
    isMobile: false,
    limit: 10,
    msgProducts: "No hay productos cargados!",
    maxProducts: 0,
    back: false,
    searchValue: "",
    categories: [],
    category: false,
  };
  function reducer(state, { type, payload }) {
    switch (type) {
      case "setProducts":
        return { ...state, products: payload };
      case "setLoading":
        return { ...state, loading: payload };
      case "setIsMobile":
        return { ...state, isMobile: payload };
      case "setLimit":
        return { ...state, limit: payload };
      case "setMsgProducts":
        return { ...state, msgProducts: payload };
      case "setMaxProducts":
        return { ...state, maxProducts: payload };
      case "setBack":
        return { ...state, back: payload };
      case "setSearchValue":
        return { ...state, searchValue: payload };
      case "setCategories":
        return { ...state, categories: payload };
      case "setCategory":
        return { ...state, category: payload };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  const loaderId = useId();

  const handleSearchChange = (e) => dispatch({ type: "setSearchValue", payload: e.target.value });

  const handleSearch = (e) => {
    e.preventDefault();
    if (state.searchValue !== "") {
      fetch(
        `https://admin.battelinifrenos.com.ar/api/products?limit=${state.limit}&offset=0&${
          state.category && "category=" + state.category
        }`
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data.length) dispatch({ type: "setMsgProducts", payload: "No se encontraron coincidencias" });

          const products = data.filter((item) =>
            item.data.name.toLowerCase().includes(state.searchValue.toLowerCase())
          );
          dispatch({
            type: "setProducts",
            payload: products,
          });
          dispatch({
            type: "setMaxProducts",
            payload: products.length,
          });
          dispatch({ type: "setBack", payload: true });
        });
    }
  };

  const handleBack = () => {
    dispatch({ type: "setBack", payload: false });
    dispatch({ type: "setSearchValue", payload: "" });
    reloadProducts();
  };

  const handleLimit = () =>
    dispatch({
      type: "setLimit",
      payload: state.isMobile ? state.limit + 5 : state.limit + 10,
    });

  useEffect(() => {
    fetch(
      `https://admin.battelinifrenos.com.ar/api/products?limit=${state.limit}&offset=0&${
        state.category && "category=" + state.category
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: "setProducts", payload: data });
        state.category && dispatch({ type: "setMsgProducts", payload: "No hay productos con esa categoria" });
      })
      .then(setTimeout(() => dispatch({ type: "setLoading", payload: false }), 500));

    dispatch({ type: "setIsMobile", payload: window.innerWidth < 1000 });
    window.addEventListener("resize", () => dispatch({ type: "setIsMobile", payload: window.innerWidth < 1000 }));
    return () =>
      window.addEventListener("resize", () => dispatch({ type: "setIsMobile", payload: window.innerWidth < 1000 }));
  }, [state.limit, refresh, state.category]);

  useEffect(() => {
    fetch(
      `https://admin.battelinifrenos.com.ar/api/products/${
        state.category ? "/category/" + state.category.toUpperCase() : "size"
      }`
    )
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: "setMaxProducts",
          payload: state.category ? data.length : data.documents,
        })
      );

    fetch("https://admin.battelinifrenos.com.ar/api/products/categoryNames")
      .then((res) => res.json())
      .then((data) => dispatch({ type: "setCategories", payload: data }))
      .catch((err) => console.log(err));
  }, [state.category]);

  const handleSelectCategories = (e) => {
    const value = e.target.value;
    dispatch({ type: "setCategory", payload: value === "false" ? false : value });
  };

  const loaders = [];
  const num = state.products.length || 5;
  for (let i = 0; i < num; i++) {
    loaders.push(
      <ProductStyled key={loaderId + i} loader={true}>
        <ProductLoader width={state.isMobile ? "155" : "200"} />
      </ProductStyled>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>Ventas</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Compra desde cualquier parte del país o acércate hasta nuestro local de ventas de
          repuestos"
        />
        <meta
          name="keywords"
          content="Venta de repuestos, Catalogo de repuestos, Repuestos camiones,recambios camiones"
        />
      </Helmet>
      <MainTitleContainer img={ShopImg}>
        <MainTitle>
          VENTA DE <br /> REPUESTOS
        </MainTitle>
        <MainSubtitle>
          Compra desde cualquier parte del país <br /> o acércate hasta nuestro local de ventas de repuestos.{" "}
        </MainSubtitle>
      </MainTitleContainer>
      <SearchBox onSubmit={handleSearch}>
        <div style={{ position: "relative", width: "69%", minWidth: "300px" }}>
          <Search
            required
            value={state.searchValue}
            onChange={handleSearchChange}
            type={"text"}
            placeholder={"¿Qué estas buscando?"}
          />
          <SearchIcon onClick={handleSearch} />
        </div>
        <input type="submit" hidden />
        <Select onChange={handleSelectCategories}>
          {state.categories.length ? (
            <>
              <option value={false}>Todas las categorias</option>
              {state.categories.map((item) => (
                <option key={item}>{item.slice(0, 1) + item.slice(1).toLowerCase()}</option>
              ))}
            </>
          ) : (
            <option>Cargando categorias</option>
          )}
        </Select>
      </SearchBox>
      {state.back && <ShowMoreBtn onClick={handleBack}>Volver</ShowMoreBtn>}
      <ProductsContainer>
        {state.loading ? (
          loaders
        ) : state.products.length ? (
          state.products.map((item) => {
            return <Product key={item.id} item={item} showPrice={showPrice} />;
          })
        ) : (
          <ProductMsg>{state.msgProducts}</ProductMsg>
        )}
      </ProductsContainer>
      {state.products.length < state.maxProducts && <ShowMoreBtn onClick={handleLimit}>Mostrar más</ShowMoreBtn>}
    </Layout>
  );
}
