import { DataContainer, DataTitle, DataDescription, Button } from "./StylesWorkshop";

import { useState, useRef, useLayoutEffect } from "react";

import closeDropdowns from "../../utils/closeDropdown";

import DropdownBtn from "./DropdownBtn";

export default function DropdownBox({ title, description, id, img }) {
  const [boxHeight, setBoxHeight] = useState(0);
  const btnRef = useRef();
  const textRef = useRef();

  const [open, setOpen] = useState(!id ? true : false);
  const handleClick = () => setOpen(!open);

  useLayoutEffect(() => setBoxHeight(textRef.current.offsetHeight), []);

  closeDropdowns(".dropdownBtn", btnRef.current, setOpen);
  return (
    <DataContainer open={open} boxHeight={boxHeight} img={img}>
      <Button aria-label={"dropdown"} className="dropdownBtn" ref={btnRef} onClick={handleClick}>
        <DropdownBtn open={open} />
      </Button>
      <DataTitle>{title}</DataTitle>
      <DataDescription ref={textRef}>{description}</DataDescription>
    </DataContainer>
  );
}
