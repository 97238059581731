import { memo } from "react";
import { ProductStyled, ProductImg, ProductTitle, ProductLink, PromotionPrice } from "./StylesShop";

import { FirstLetterUppercase } from "../../utils/FirstLetterUppercase";

const Product = memo(({ item, showPrice }) => {
  const handleMove = (e) => {
    const item = e.target;
    let x = e.pageX - item.offsetLeft - item.offsetParent.offsetLeft;
    let y = e.pageY - item.offsetTop - item.offsetParent.offsetTop;
    let polygonX = 50;
    let polygonY = 0;
    if (x < 40) {
      polygonX += (40 - x) * 5;
      polygonY -= (40 - x) * 2;
      x = 40;
    }
    if (x > 210) {
      polygonX += (210 - x) * 5;
      polygonY += (210 - x) * 2;
      x = 210;
    }
    if (y < 45) {
      polygonY += (45 - y) * 5;
      y = 45;
    }
    item.style.setProperty("--x", x + "px");
    item.style.setProperty("--y", y + "px");
    item.style.setProperty("--polygonX", polygonX + "%");
    item.style.setProperty("--polygonY", polygonY + "%");
    item.style.setProperty("--display", "flex");
  };

  return (
    <ProductLink to={`/ventas/${item.id}`}>
      <ProductStyled onMouseMove={handleMove}>
        <ProductImg
          src={`https://admin.battelinifrenos.com.ar/public/${item.data.image}`}
          alt={`Imagen de ${FirstLetterUppercase(item.data.name)}`}
        />
        <ProductTitle>{FirstLetterUppercase(item.data.name)}</ProductTitle>
        {showPrice ? (
          item.data.promotion !== "0" ? (
            <div>
              <PromotionPrice>$ {item.data.price}</PromotionPrice>
              <span>$ {item.data.promotion}</span>
            </div>
          ) : (
            <span>$ {item.data.price}</span>
          )
        ) : (
          <></>
        )}
      </ProductStyled>
    </ProductLink>
  );
});

export default Product;
