import Layout from "../Layout/Layout";

import Aside from "./Aside";

import {
  MainImgContainer,
  MainImgLogo,
  BoxContainer,
  MainTitle,
  MainSubtitle,
  Box,
  BoxTitle,
  BoxIcon,
  BoxText,
  BoxImagesContainer,
  BoxImagesFragment,
  BoxImages,
  BoxLine,
  BoxYear,
} from "./StylesHome";
import { Br } from "../Theme/Styles";

import mainLogo from "../../assets/main-logo.png";
import logo1 from "../../assets/garantia-1.png";
import logo2 from "../../assets/garantia-2.png";
import logo3 from "../../assets/logo.png";
import colvenImg from "../../assets/home-colven.png";
import vigiaImg from "../../assets/home-vigia.png";

import { Helmet } from "react-helmet-async";

import useObserver from "../../customHooks/useObserver";

import { memo } from "react";

const Home = memo(() => {
  const { observe, elementRef } = useObserver();

  return (
    <Layout>
      <Helmet>
        <title>Inicio</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Calidad, Seguridad y Eficiencia. Más de 70 años al servicio del transporte. Frenos Avenida, Nuestro taller se encuentra certificado por los entes AITA y CNTSV"
        />
        <meta
          name="keywords"
          content="Frenos Avenida, Calidad, Seguridad y Eficiencia, Servicio del transporte, Taller de frenos homologado"
        />
      </Helmet>
      <MainImgContainer>
        <MainImgLogo src={mainLogo} alt={"Imagen de fondo"} />
        <MainTitle>
          CALIDAD SEGURIDAD <Br /> Y EFICIENCIA
        </MainTitle>
        <MainSubtitle>Más de 70 años al servicio del transporte</MainSubtitle>
      </MainImgContainer>

      <BoxContainer>
        <Box icon={true} width={"50%"}>
          <div style={{ position: "relative" }}>
            <BoxTitle>
              TALLER DE FRENOS
              <br />
              HOMOLOGADO
            </BoxTitle>
            <BoxIcon />
          </div>
          <BoxText>
            Nuestro taller se encuentra certificado por los entes AITA (Asociación de Ingenieros y
            Técnicos del Automotor) y CNTSV (Comisión Nacional de Tránsito y Seguridad Vial) según
            la Diposición 25/2009 de la Secretaría de Transporte Automotor.
          </BoxText>
        </Box>

        <Box icon={true} width={"50%"}>
          <div style={{ position: "relative" }}>
            <BoxTitle>
              AGENTES OFICIALES
              <br />
              DE COLVEN
            </BoxTitle>
            <BoxIcon />
          </div>
          <BoxText>
            Somos Agentes Oficiales certificados por Colven.
            <br /> Calidad y el mejor servicio.
            <div>
              <img src={colvenImg} alt="Imagen Colven" />
              <img src={vigiaImg} alt="Imagen Vigia" />
            </div>
          </BoxText>
        </Box>
      </BoxContainer>

      <Aside />

      <BoxContainer>
        <Box grow={true} width={"50%"}>
          <BoxTitle>
            GARANTÍA
            <br />
            ASEGURADA
          </BoxTitle>
          <BoxText>Tres Generaciones al servicio de nuestros clientes.</BoxText>
          <BoxImagesContainer ref={elementRef}>
            <BoxImagesFragment>
              <BoxImages
                src={logo2}
                paddingR={true}
                delay={0}
                animation={observe}
                alt={"Imagen de logo 1984"}
              />
              <BoxYear>1984</BoxYear>
            </BoxImagesFragment>

            <BoxImagesFragment>
              <BoxImages
                src={logo1}
                paddingR={true}
                paddingL={true}
                delay={0.3}
                animation={observe}
                alt={"Imagen de logo 2003"}
              />
              <BoxYear>2003</BoxYear>
            </BoxImagesFragment>

            <BoxImagesFragment>
              <BoxImages
                src={logo3}
                paddingL={true}
                delay={0.6}
                animation={observe}
                alt={"Imagen de logo 2021"}
              />
              <BoxYear>2021</BoxYear>
            </BoxImagesFragment>

            <BoxLine animation={observe} />
          </BoxImagesContainer>
        </Box>
        <Box width={"40%"}>
          <BoxTitle>
            SOLUCIONES
            <br />
            PERSONALIZADAS
          </BoxTitle>
          <BoxText>
            Resolvemos problemas. Es por eso que trabajamos con un servicio personalizado para
            brindar una solucion eficiente para cada uno de nuestros clientes.
          </BoxText>
        </Box>
      </BoxContainer>
    </Layout>
  );
});

export default Home;
