import AdminHeader from "./AdminHeader";
import AdminControllers from "./AdminControllers";
import AdminProducts from "./AdminProducts";

import { ProductsMain } from "./StylesProducts";
import { Helmet } from "react-helmet-async";

import Login from "../Login/Login";

import { useContext, useState, useCallback } from "react";
import { Context } from "../Context/ContextProvider";
import { useReducer } from "react";

function Dashboard() {
  const [route, setRoute] = useState("/admin");
  const [products, setProducts] = useState([]);
  const [msgProducts, setMsgProducts] = useState("No hay productos cargados!");
  const [category, setCategory] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleSelection = useCallback((e) => {
    if (e.target.value === "Todos") {
      setRoute("/admin");
    } else if (e.target.value === "Borrador") {
      setRoute("/admin/priv");
    } else {
      setRoute("");
    }
  }, []);

  const handleSelectCategories = (e) => {
    const value = e.target.value;
    setCategory(value === "false" ? false : value);
  };

  const initialState = {
    loading: true,
    modal: false,
    modalInfo: {},
    allCheck: false,
    height: 0,
    limit: 10,
    page: 1,
    offset: 0,
    maxPage: 1,
  };
  function reducer(state, { type, payload }) {
    switch (type) {
      case "setLoading":
        return { ...state, loading: payload };
      case "setModal":
        return { ...state, modal: payload };
      case "setModalInfo":
        return { ...state, modalInfo: payload };
      case "setAllCheck":
        return { ...state, allCheck: payload };
      case "setHeight":
        return { ...state, height: payload };
      case "setLimit":
        return { ...state, limit: payload };
      case "setPage":
        return { ...state, page: payload };
      case "setOffset":
        return { ...state, offset: payload };
      case "setMaxPage":
        return { ...state, maxPage: payload };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const [search, setSearch] = useState(false);

  return (
    <div style={{ maxHeight: "100vh", overflow: "hidden" }}>
      <AdminHeader />
      <ProductsMain>
        <AdminControllers
          setProducts={setProducts}
          route={route}
          handleSelection={handleSelection}
          setMsgProducts={setMsgProducts}
          handleSelectCategories={handleSelectCategories}
          category={category}
          categories={categories}
          setCategories={setCategories}
          state={state}
          dispatch={dispatch}
          setSearch={setSearch}
        />
        <AdminProducts
          setProducts={setProducts}
          route={route}
          products={products}
          msgProducts={msgProducts}
          category={category}
          categories={categories}
          state={state}
          dispatch={dispatch}
          search={search}
        />
      </ProductsMain>
    </div>
  );
}

export default function Admin() {
  const { token, setToken } = useContext(Context);

  return (
    <>
      <Helmet>
        <title>Administracion</title>
      </Helmet>
      <div style={{ minHeight: "100vh" }}>
        {token ? <Dashboard /> : <Login setToken={setToken} />}
      </div>
    </>
  );
}
