import styled from "styled-components";

export const FooterContainer = styled.div`
    background-color: var(--color-grey);
    margin:  5px 2.5% 70px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1000px) {
        margin:  5px 5% 20px;
    }
`

export const FooterList = styled.ul`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`

export const FooterItem = styled.li`
    margin: 20px 0;
    color: var(--color-grey-3);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    text-align: center;

    a{
        color: var(--color-grey-3);
    }

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        text-align: left;
        width: 30%;
        flex-grow: 1;
        order: ${props => props.order};
        position: relative;

        svg {
            transition: transform .2s ease;
            position: relative;
            top: 2px;
            right: 5px;
        }

        :hover {
            svg {
                transform: scale(1.8);
            }
        }
    }
`

export const Icon = styled.div`
    color: var(--color-blue);
`

export const CopyRightBox = styled.div`
    margin: 5px;
    background-color: var(--color-light-grey);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media screen and (min-width: 1000px) {
        margin: 5px 15px 15px;
    }
`

export const CopyRigth = styled.p`
    font-size: 10px;
    margin-left: 10px;
    color: var(--color-grey-3);

    @media screen and (min-width: 1000px) {
        font-size: 12px;
        margin-left: 50px;
    }
`

export const CopyRightLogo = styled.img`
    height: 30px;
    transition: all .2s ease;
    filter: drop-shadow(0 0 #0002);

    @media screen and (min-width: 1000px) {
        height: 40px;
        margin-right: 50px;
        cursor: pointer;

        :hover {
            transform: translateY(-2.5px);
            filter: drop-shadow(0 2.5px #0002);
        }
    }
`