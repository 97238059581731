import styled from "styled-components";

import { AiOutlineWhatsApp } from "react-icons/ai";

export const Product = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 20px;
    margin-bottom: 10px;
    gap: 10px;
    padding: 20px 0;

    @media screen and (min-width: 1000px){
        padding: 20px;
        flex-direction: row;
        width: 100%;
        height: 500px;
        gap: 50px;
        justify-content: space-between;
    }
`

export const Title = styled.h1`
    font-family: 'Inter', sans-serif;
    max-width: 100%;
    word-wrap: break-word;
`

export const ProductImg = styled.img`
    max-width: 50%;
    min-width: 50%;
    object-fit: contain;

    @media screen and (min-width: 1000px){
        margin-right: 50px;
        height: 100%;
    }
`

export const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    margin-left: 5%;
    width: 90%;
    
    @media screen and (min-width: 1000px){
        height: 90%;
        width: 50%;
        margin-right: 20px;
        margin-left: unset;
        justify-content: center;
        gap: 10px;
    }
`

export const Description = styled.pre`
    max-height: 500px;
    width: 100%;
    overflow: auto;
    word-wrap: break-word;
    white-space: pre-wrap;

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    :-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
      border: 2px solid #f2f2f2;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #aaa;
    }

    @media screen and (min-width: 1000px){
        min-height: 250px;
    }
`

export const ProductBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--color-grey);
    border-top: 1px solid var(--color-grey);
`

export const WppBtn = styled.a`
    background-color: #25D366;
    border: none;
    padding: 10px 0;
    border-radius: 20px;
    width: 300px;
    margin-top: 10px;
    color: var(--color-dark-blue);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;

    @media screen and (min-width: 1000px){
        cursor: pointer;
    }
`

export const WppIcon = styled(AiOutlineWhatsApp)`
    font-size: 25px;
    margin-right: 10px;
`