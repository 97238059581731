import Layout from "../Layout/Layout";
import DropdownBox from "./DropdownBox";

import { MainTitleContainer, MainTitle, MainSubtitle } from "./StylesWorkshop";

import { Helmet } from "react-helmet-async";

import { useEffect, useState, useId } from "react";

import workShopImg from "../../assets/WorkShop.png";

export default function Workshop() {
  const [data, setData] = useState([]);

  const id = useId();

  useEffect(() => {
    fetch("/data/workshop.json")
      .then((res) => res.json())
      .then((info) => setData(info));
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Taller</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Cambio de discos y pastillas delanteras y traseras. Reparación y colocación de compresores en general. Colocacion de perno de enganche..."
        />
        <meta
          name="keywords"
          content="Remolques y acoplados, Camiones, Vehiculos utilitarios, Vehiculos livianos, Mecánica camiones"
        />
      </Helmet>

      <MainTitleContainer img={workShopImg}>
        <MainTitle>
          TALLER DE <br /> FRENOS
        </MainTitle>
        <MainSubtitle>Desde 1950 tu problema es nuestro</MainSubtitle>
      </MainTitleContainer>

      {data.map((item, index) => {
        if (item.bg) {
          return (
            <DropdownBox
              key={id + index}
              title={item.title}
              description={item.description}
              id={index}
              img={item.bg}
            />
          );
        } else {
          return (
            <DropdownBox
              key={id + index}
              title={item.title}
              description={item.description}
              id={index}
            />
          );
        }
      })}
    </Layout>
  );
}
