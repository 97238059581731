import { writeFile, utils } from 'xlsx';

export const exportToExcel = (headTable, bodyTable) => {
    const data = [headTable, ...bodyTable];

    const sheetName = "Datos";
    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(data);

    utils.book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, "Productos de Frenos Avenida Web App.xlsx");
}