import { useEffect, useState, useRef } from "react";

export default function useObserver() {
  const [observe, setObserve] = useState(false);
  const elementRef = useRef();

  useEffect(() => {
    const onChange = (entries, observer) => {
      entries.forEach((el) => {
        if (el.isIntersecting) {
          setObserve(true);
          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(onChange, {
      threshold: 1,
    });

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, []);

  return { observe, elementRef };
}
