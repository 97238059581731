import Layout from "../Layout/Layout";
import DropdownBox from "./DropdownBox";
import DropdownBoxWithBoxes from "./DropdownBoxWithBoxes";

import { Helmet } from "react-helmet-async";

import { useEffect, useState, useId } from "react";

import { MainTitleContainer, MainTitle, MainSubtitle } from "../Workshop/StylesWorkshop";

import AbsImg from "../../assets/ABS.png";

export default function ABS() {
  const [data, setData] = useState([]);

  const id = useId();

  useEffect(() => {
    fetch("/data/abs.json")
      .then((res) => res.json())
      .then((info) => setData(info));
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>ABS-EBS</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Contamos con el conocimiento y las herramientas necesarias para brindarte la mejor
          solución en su sistema de frenos ABS/EBS"
        />
        <meta name="keywords" content="Reparación frenos, Frenos ABS, Frenos EBS" />
      </Helmet>

      <MainTitleContainer img={AbsImg}>
        <MainTitle>
          ABS
          <br />
          EBS
        </MainTitle>
        <MainSubtitle>
          Contamos con el conocimiento y las herramientas necesarias para brindarte la mejor
          solución en su sistema de frenos ABS/EBS
        </MainSubtitle>
      </MainTitleContainer>

      {data.map((item, index) => {
        if (item.hasOwnProperty("info")) {
          return <DropdownBoxWithBoxes item={item} key={id + index} />;
        } else {
          return <DropdownBox item={item} key={id + index} />;
        }
      })}
    </Layout>
  );
}
