import styled from "styled-components";

export const MainTitleContainer = styled.div`
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 110vw 300px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
    border-radius: 10px;

    @media screen and (min-width: 1000px) {
        background-size: 100vw 100vh;
        padding-left: 80px;
        height: 400px;
    }
`

export const MainTitle = styled.h1`
    font-size: 60px;
    font-weight: 100;
    line-height: .8;
    color: var(--color-dark-blue);

    @media screen and (min-width: 1000px) {
        font-size: 90px;
    }
`

export const MainSubtitle = styled.p`
    @media screen and (min-width: 1000px) {
        max-width: 700px;
        font-size: 22px;
    }
`

export const DataContainer = styled.div`
    background-color: #fafafa;
    margin: 10px 0;
    border-radius: 10px;
    padding: 0 15px;
    height: ${props => props.open ? `calc(${props.boxHeight}px + 60px)` : '60px'};
    overflow: hidden;
    position: relative;
    transition: height .3s ease;

    @media screen and (min-width: 1000px) {
        background-image: url(${props => props.open ? props.img : ''});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
        padding: 0 30px;
        height: ${props => props.open ? `calc(${props.boxHeight}px + 80px)` : '80px'};
    }
`

export const DataTitle = styled.h5`
    font-size: 22px;
    font-weight: 100;
    height: 60px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1000px) {
        height: 80px;
    }
`

export const DataDescription = styled.pre`
    line-height: 1.8;
    text-align: left;
    padding-bottom: 15px;
    color: var(--color-dark-blue);
    word-wrap: break-word;
    white-space: pre-wrap;

    @media screen and (min-width: 1000px) {
        padding-bottom: 50px;
    }
`

export const Button = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background-color: transparent;

    @media screen and (min-width: 1000px) {
        cursor: pointer;
    }
`