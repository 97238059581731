import Layout from "../Layout/Layout";

import { Container, Title, Form, InputBox, Label, Input, Textarea, Send } from "./StylesContact";

import { Helmet } from "react-helmet-async";
import { useState } from "react";

import { toastSuccess, toastError, toastWarn } from "../../utils/Toast.js";

import { verifyFields } from "../../utils/verifyFields";

export default function Contact() {
  const [inputValues, setInputValues] = useState({
    name: "",
    mail: "",
    message: "",
  });

  const handleChange = ({ target }) => {
    setInputValues((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();

    const areValid = verifyFields(inputValues, 5);

    if (areValid) {
      fetch("https://admin.battelinifrenos.com.ar/mail", {
        method: "POST",
        body: JSON.stringify({
          name: inputValues.name,
          mail: inputValues.mail,
          message: inputValues.message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => (data.error ? toastError(data.error) : toastSuccess(data.msg)))
        .catch((error) => toastWarn("Error: no se pudo enviar el mensaje"))
        .finally(
          setInputValues({
            name: "",
            mail: "",
            message: "",
          })
        );
    } else {
      toastError("Error: todos los campos deben estar completos y con un minimo de 5 caracteres");
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Contacto</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Contactanos mediante este formulario o mediante los numeros que aparecen al final de la pagina!"
        />
        <meta name="keywords" content="Contacto, Formulario, Mail, Frenos avenida contacto" />
      </Helmet>
      <Container>
        <Title>CONTACTO</Title>
        <Form onSubmit={submit}>
          <InputBox>
            <Label htmlFor={"Nombre"}>Nombre</Label>
            <Input
              value={inputValues.name}
              type="text"
              id="Nombre"
              placeholder="Escribe tu nombre aqui"
              onChange={handleChange}
              name={"name"}
              required
            />
          </InputBox>
          <InputBox>
            <Label htmlFor={"EMail"}>EMail</Label>
            <Input
              value={inputValues.mail}
              type="email"
              id="EMail"
              placeholder="Escribe tu email aqui"
              onChange={handleChange}
              name={"mail"}
              required
            />
          </InputBox>
          <InputBox>
            <Label htmlFor={"Mensaje"}>Mensaje</Label>
            <Textarea
              value={inputValues.message}
              placeholder="Escribe tu mensaje aqui"
              id="Mensaje"
              onChange={handleChange}
              name={"message"}
              required
            />
          </InputBox>
          <Send type="submit" value={"Enviar consulta"} />
        </Form>
      </Container>
    </Layout>
  );
}
