import {
  ModalBg,
  ModalContainer,
  ModalTitle,
  InputsContainer,
  ProductLabel,
  ProductInput,
  ProductBox,
  ProductFragment,
  ProductBoxInput,
  ProductDescription,
  CloseButton,
  SaveButtons,
  SaveButton,
} from "./StylesModal";

import { createPortal } from "react-dom";
import { useState, useRef } from "react";

import ImageModal from "./ImageModal";

import { updateProduct } from "../../../utils/updateProduct";
import { SelectCategory, SelectDiv, SelectLabel } from "../StylesProducts";

export default function ModalEditProduct({
  closeModal,
  productInfo,
  reloadProducts,
  token,
  categories,
}) {
  const [inputValues, setInputValues] = useState({
    name: productInfo.data.name,
    price: productInfo.data.price,
    promotion: productInfo.data.promotion,
    description: productInfo.data.description,
    category: productInfo.data.category,
    tags: productInfo.data.tags,
  });
  const image = productInfo.data.image;
  const prevImage = image;

  const handleChange = (e) => {
    setInputValues((prevValues) => {
      return {
        ...prevValues,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fileRef = useRef(null);

  const editProduct = async (priv) => {
    const imageData = {
      file: fileRef.current.files[0],
      prevImage,
    };
    const parentProps = {
      productInfo,
      closeModal,
      reloadProducts,
    };
    await updateProduct(priv, imageData, image, token, inputValues, parentProps);
  };

  const handleSelectCategories = (e) => {
    const value = e.target.value;
    setInputValues({ ...inputValues, category: value === "false" ? false : value.toUpperCase() });
  };

  return createPortal(
    <ModalBg>
      <ModalTitle>Editar Producto</ModalTitle>

      <ModalContainer onSubmit={(e) => e.preventDefault()}>
        <ImageModal text={"Editar"} imageUrl={image} ref={fileRef} />
        <InputsContainer>
          <div style={{ position: "relative" }}>
            <ProductLabel left={"37"}>Nombre</ProductLabel>
            <ProductInput
              placeholder={"Nombre"}
              value={inputValues.name}
              onChange={handleChange}
              name={"name"}
            />
          </div>

          <ProductBox>
            <ProductFragment width={50}>
              <ProductLabel left={"0"}>Precio</ProductLabel>
              <ProductBoxInput
                placeholder={"Precio"}
                value={inputValues.price}
                name={"price"}
                onChange={handleChange}
              />
            </ProductFragment>

            <ProductFragment width={50}>
              <ProductLabel left={"0"}>Promocion</ProductLabel>
              <ProductBoxInput
                placeholder={"Promocion"}
                value={inputValues.promotion}
                name={"promotion"}
                onChange={handleChange}
              />
            </ProductFragment>
          </ProductBox>
          <SelectDiv>
            <SelectLabel>Categoria</SelectLabel>
            <SelectCategory onChange={handleSelectCategories}>
              {categories.length ? (
                <>
                  <option>
                    {inputValues.category.slice(0, 1) + inputValues.category.slice(1).toLowerCase()}
                  </option>
                  {categories.map((item) => (
                    <option key={item}>{item.slice(0, 1) + item.slice(1).toLowerCase()}</option>
                  ))}
                </>
              ) : (
                <option>Cargando categorias</option>
              )}
            </SelectCategory>
          </SelectDiv>
          <div style={{ position: "relative" }}>
            <ProductLabel left={"37"}>Descripcion</ProductLabel>
            <ProductDescription
              placeholder={"Descripcion"}
              value={inputValues.description}
              onChange={handleChange}
              name={"description"}
            />
          </div>
          <div style={{ position: "relative" }}>
            <ProductLabel left={"37"}>Tag</ProductLabel>
            <ProductInput
              placeholder={"Tag"}
              value={inputValues.tags}
              onChange={handleChange}
              name={"tags"}
            />
          </div>
        </InputsContainer>

        <SaveButtons>
          <SaveButton bg={"dark"} order={3} onClick={() => editProduct(false)}>
            Publicar
          </SaveButton>
          <SaveButton color={"dark"} order={2} onClick={() => editProduct(true)}>
            Guardar como borrador
          </SaveButton>
          <CloseButton onClick={closeModal}>Cancelar</CloseButton>
        </SaveButtons>
      </ModalContainer>
    </ModalBg>,

    document.body
  );
}
