import {
  ProductBoxTitle,
  ProductPublic,
  ProductsBody,
  ProductBoxEdit,
  ProductEditIcon,
  ProductDeleteIcon,
  ProductsImg,
} from "./StylesProducts";

import { toastSuccess, toastError, toastWarn } from "../../utils/Toast.js";
import { swalDelete } from "../../utils/Swal";

import Checkbox from "./Checkbox/Checkbox";

import { FirstLetterUppercase } from "../../utils/FirstLetterUppercase";

const Products = (props) => {
  const { product, modalToggle, setModalInfo, setDeleteProducts, deleteProducts, token, reloadProducts, allCheck } =
    props;

  const activeModal = (data) => {
    modalToggle();
    setModalInfo("setModalInfo", data);
  };

  const handleDelete = (id) => {
    swalDelete({
      title: "Estas seguro que quieres eliminar este producto?",
      text: "Una vez eliminado no se podra recuperar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch("https://admin.battelinifrenos.com.ar/api/products", {
          method: "DELETE",
          body: JSON.stringify({ productsId: [id] }),
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        })
          .then((res) => res.json())
          .then((data) => (data.error ? toastError(data.error) : toastSuccess("Producto eliminado exitosamente!")))
          .then(reloadProducts)
          .catch((error) => toastWarn("No se ha podido eliminar el producto"));
      }
    });
  };

  return (
    <>
      <ProductsBody>
        <Checkbox
          allCheck={allCheck}
          id={product.id}
          setDeleteProducts={setDeleteProducts}
          deleteProducts={deleteProducts}
        />
        <ProductsImg src={`https://admin.battelinifrenos.com.ar/public/${product.data.image}`} />
        <ProductBoxTitle>{FirstLetterUppercase(product.data.name)}</ProductBoxTitle>
        <ProductPublic color={product.data.public ? "active" : undefined}>
          {product.data.public ? "Publicado" : "Borrador"}
        </ProductPublic>
        <ProductBoxEdit>
          <ProductEditIcon onClick={() => activeModal(product)} />
          <ProductDeleteIcon onClick={() => handleDelete(product.id)} />
        </ProductBoxEdit>
      </ProductsBody>
    </>
  );
};

export default Products;
