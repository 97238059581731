import * as XLSX from "xlsx";

export const getExcelData = async (target) => {
    let sheetsData = [];
    let reader = new FileReader();
    reader.readAsArrayBuffer(target.files[0]);

    await new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            workbook.SheetNames.forEach(function (sheetName) {
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                sheetsData.push(XL_row_object);
            });
            resolve();
        };
    });

    return sheetsData[0];
}