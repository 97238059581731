import Swal from "sweetalert2";

export const swalDelete = async ({ title, text }) => Swal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "var(--color-dark-blue)",
    cancelButtonColor: "var(--color-grey)",
    cancelButtonText: "Cancelar",
    confirmButtonText: "Si, eliminar",
    reverseButtons: true,
})

export const swalQuestion = async ({ title, text }) => Swal.fire({
    title,
    text,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "var(--color-dark-blue)",
    cancelButtonColor: "var(--color-grey)",
    cancelButtonText: "Cancelar",
    confirmButtonText: "Si",
    reverseButtons: true,
})

export const swalResponse = async ({ title, text, icon }) => Swal.fire({
    title,
    text,
    icon,
    confirmButtonColor: "var(--color-dark-blue)",
    confirmButtonText: "Cerrar",
});